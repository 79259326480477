import BaseChart from "../charts/BaseChart";
import ChartWrapper from "../charts/ChartWrapper";
import I18n from "../../models/i18n";
import React from "react";
import PropTypes from "prop-types";
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from "react-vis";
import ChartModalContainer from "../CommonComponents/PMChartInfoModal";

class Chart extends BaseChart {
  static propTypes = {
    stations: PropTypes.array,
    selectedStations: PropTypes.array,
    data: PropTypes.array,
    graph: PropTypes.string
  };

  state = {
    value: false
  };

  hintValue = "";

  render() {
    const { width, graph, data } = this.props;

    const { value } = this.state;
    const { h, w } = this.getHeight(width || 600);

    const yValuesArray = data.map(item => {
      if (graph === "pm25") {
        return item.PM2_5;
      } else {
        return item[graph];
      }
    });
    const yMaxValue = Math.max.apply(Math, yValuesArray);
    const yMinValue = Math.min.apply(Math, yValuesArray);

    const xStart = new Date(data[0].recordedAt);
    const xEnd = new Date(data[data.length - 1].recordedAt);
    const cutOffPmVeryGood = [
      { x: xStart, y: 0.2 },
      { x: xEnd, y: 0.2 }
    ];
    const cutOffPmGood = [
      { x: xStart, y: 10 },
      { x: xEnd, y: 10 }
    ];
    const cutOffPmFair = [
      { x: xStart, y: 20 },
      { x: xEnd, y: 20 }
    ];
    const cutOffPmPoor = [
      { x: xStart, y: 25 },
      { x: xEnd, y: 25 }
    ];
    const cutOffPmVeryPoor = [
      { x: xStart, y: 50 },
      { x: xEnd, y: 50 }
    ];

    let title;
    let subtitle;
    let yAxisGraph;
    let label;

    // Valore massimo e minimo del grafico
    yAxisGraph = [yMinValue - 10, yMaxValue + 10];

    if (graph === "temp") {
      title = I18n.translate("data", "tabellaTemp_title");
      subtitle = I18n.translate("data", "tabellaTemp_subtitle");
      yAxisGraph = [-15, 45];
      label = "° C";
    } else if (graph === "pm25") {
      title = I18n.translate("data", "tabellaPM25_title");
      subtitle = I18n.translate("data", "tabellaPM25_subtitle");
      label = "PM 2.5";
    } else if (graph === "o3") {
      title = I18n.translate("data", "tabella_o3_title");
      // subtitle = I18n.translate('data', 'tabellao3_subtitle');

      label = "O3";
    } else if (graph === "hum") {
      title = I18n.translate("data", "tabellaHum_title");
      subtitle = I18n.translate("data", "tabellaHum_subtitle");

      label = "Umidità";
    } else if (graph === "nox") {
      title = I18n.translate("data", "tabella_nox_title");
      // subtitle = I18n.translate('data', 'tabellaHum_subtitle');
      // yAxisGraph = [0, yMaxValue + 1];

      label = "NOx";
    }

    return (
      <div>
        <ChartWrapper
          title={title}
          subtitle={subtitle}
          modalContent={graph === "temp" ? null : <ChartModalContainer />}
        >
          <XYPlot
            xType="time"
            width={w}
            height={h}
            yDomain={yAxisGraph}
            onMouseLeave={() => this.setState({ value: false })}
            onTouchEnd={() => this.setState({ value: false })}
            margin={
              width < 500
                ? { left: 50, right: 10, top: 10, bottom: 50 }
                : { left: 60, right: 10, top: 10, bottom: 50 }
            }
          >
            <HorizontalGridLines />
            <VerticalGridLines />
            <XAxis title="Time" tickLabelAngle={-45} />
            <YAxis />
            <ChartLabel
              text={label}
              className="alt-y-label"
              includeMargin={false}
              xPercent={width >= 900 ? -0.04 : width >= 500 ? -0.075 : -0.12}
              yPercent={0.5}
              style={{
                transform: "rotate(-90)",
                textAnchor: "middle"
              }}
            />
            {this.props.selectedStations.map((station, i) => {
              if (!station) {
                return null;
              }
              const { id, color } = this.getStation(station);
              const param = this.props.graph;
              const data = this.props.data
                .filter(item => item.stationId === id)
                .map(item => {
                  return {
                    x: new Date(item.recordedAt),
                    y: this.getYAxisForGraph(item, param)
                  };
                });

              return (
                <LineSeries
                  key={i}
                  data={data}
                  curve={"curveMonotoneX"}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX(
                      "valueHum",
                      valueSerie,
                      Math.abs(event.event.offsetX - event.innerX - 10)
                    );
                  }}
                  onSeriesMouseOver={value =>
                    (this.hintValue = { ...value, name: "Linea A" })
                  }
                  color={color}
                  title={color}
                />
              );
            })}
            {graph === "pm25" && [
              <LineSeries
                key={0}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmVeryGood}
                stroke="#1DE3E0"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Very Good"
              />,
              <LineSeries
                key={1}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmGood}
                stroke="#1DE326"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Good"
              />,
              <LineSeries
                key={2}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmFair}
                stroke="#FFEC33"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Fair"
              />,
              <LineSeries
                key={3}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmPoor}
                stroke="#FF9C33"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Poor"
              />,
              <LineSeries
                key={4}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmVeryPoor}
                stroke="#FF0000"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Very poor"
              />
            ]}
            {value && (
              <Hint value={value}>
                <div
                  style={{
                    background: "black",
                    opacity: "0.5",
                    padding: "10px"
                  }}
                >
                  Date time: <strong>{this.state.ts.toLocaleString()}</strong>
                  {this.props.selectedStations.map(station => {
                    return (
                      <div key={station}>
                        {this.getStation(station).name}: {this.state[station]}
                      </div>
                    );
                  })}
                </div>
              </Hint>
            )}
          </XYPlot>
        </ChartWrapper>
      </div>
    );
  }

  getStation = code => {
    for (let i = 0; i < this.props.stations.length; i++) {
      if (this.props.stations[i].code === code) {
        return this.props.stations[i];
      }
    }
    console.error('Unable to find station: "%s"', code);
    return false;
  };

  /**
   *
   * @param {*} item
   * @param {*} graph
   * @returns
   */
  getYAxisForGraph(item, graph) {
    if (graph === "pm25") return item.PM2_5;

    return item[graph];
  }
}

export default Chart;
